import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class AirlinesProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('/airlines', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/airlines/${id}`)
  }

  create (payload) {
    this.setHeader(getAuthToken())
    return this.post('/airlines', payload)
  }

  updateItemById (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/airlines/${payload.id}`, payload)
  }

  deleteItemById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/airlines/${id}`)
  }
}

export default AirlinesProvider
