<template>
  <div class="settings-container">
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          outlined
          depressed
          :ripple="false"
          style="width: fit-content; background-color: white;"
          @click="changeRoute('SettingsPackage')">
          <v-icon>
            mdi-chevron-left
          </v-icon>
          <span>
            ย้อนกลับ
          </span>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="4">
        <SearchBox
          v-model="query.search"
          :disabled="loading"
          @search="getItems(true)" />
      </v-col>
      <v-spacer />
      <v-col
        cols="12"
        md="auto">
        <v-btn
          color="primary"
          outlined
          depressed
          block
          :ripple="false"
          style="width: fit-content; background-color: white;"
          @click="createItem()">
          <span>
            เพิ่มสายการบิน
          </span>
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          hide-default-footer>
          <template #[`item.actions`]="{ item }">
            <v-btn
              color="primary"
              icon
              :disabled="loading"
              @click="editItem(item)">
              <v-icon>
                mdi-square-edit-outline
              </v-icon>
            </v-btn>
            <v-btn
              class="ml-1"
              color="error"
              icon
              :disabled="loading"
              @click="confirmDialogToggle(item)">
              <v-icon>
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <div class="d-flex justify-end">
          <v-pagination
            v-model="query.page"
            :length="totalPages"
            :total-visible="7"
            circle
            color="primary" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AirlinesProvider from '@/resources/airlines.provider'
import SearchBox from '@/components/SearchBox.vue'

const AirlinesService = new AirlinesProvider()

export default {
  components: { SearchBox },
  data: () => ({
    loading: false,
    headers: [
      {
        text: 'รายการ',
        value: 'name',
        class: 'table-header',
        sortable: false
      },
      {
        text: '',
        value: 'actions',
        class: 'table-header',
        sortable: false,
        align: 'right'
      }
    ],
    items: [],
    query: {
      page: 1,
      limit: 10,
      search: ''
    },
    totalPages: 1
  }),
  watch: {
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    }
  },
  mounted () {
    this.getItems()
  },
  methods: {
    changeRoute (to) {
      this.$router.push({ name: to })
    },
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await AirlinesService.getItems(this.query)

        this.items = data.results
        this.totalPages = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onRemove (id) {
      try {
        this.loading = true

        await AirlinesService.deleteItemById(id)

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: 'ลบข้อมูลเสร็จสิ้น',
          type: 'success',
          timeout: 2000
        })

        this.getItems(true)
      } catch (error) {
        console.error('onRemove', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    createItem () {
      this.$router.push({ name: 'SettingsAirlineCreate' })
    },
    editItem (item) {
      this.$router.push({
        name: 'SettingsAirlineEdit',
        params: {
          id: item.id
        }
      })
    },
    confirmDialogToggle (item) {
      this.$store.dispatch('ConfirmDialog/setDialog', {
        active: true,
        title: 'แจ้งเตือน',
        text: `คุณยืนยันที่จะลบ "${item.name}" ใช่หรือไม่?`,
        onConfirm: () => this.onRemove(item.id)
      })
    }
  }
}
</script>

<style scoped>
.settings-container {
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
